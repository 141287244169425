import hagen from "hagen";
import fb from "firebase/app";
import getFirebase from "./firebase";

const firebase = getFirebase();

export const createAnonymousAccount = async () => {
	hagen.log(`AUTH`, `Signing in anonymously`);

	try {
		await firebase.auth().signInAnonymously();
	} catch (error) {
		hagen.error(`AUTH`, `Couldn't sign in anonymously`);
		hagen.error(`AUTH`, `${error.code}: ${error.message}`);
	}
};

const getEmail = () => {
	// get the saved email address from local storage
	let email = window.localStorage.getItem(`emailForSignIn`);

	// if it isn't there, ask for it
	if (!email) {
		// eslint-disable-next-line no-alert
		email = window.prompt(
			`Please provide your email for confirmation`
		);
	}
	return email;
};

export const signInWithPasswordlessEmail = async () => {
	hagen.log(`AUTH`, `Signing up with passwordless email link`);

	// get the saved email address from local storage or by asking
	const email = getEmail();

	// create the login credential
	const credential = fb.auth.EmailAuthProvider.credentialWithLink(
		email,
		window.location.href
	);

	try {
		// link it to the current logged-in user
		await firebase
			.auth()
			.currentUser.linkWithCredential(credential);

		hagen.log(
			`AUTH`,
			`Account linked to existing anonymous account`
		);

		// delete the saved email address from local storage and remove url params
		resetPageState();
	} catch (error) {
		// if the email is already in use
		if (
			error.code === `auth/email-already-in-use` ||
			error.code === `auth/provider-already-linked`
		) {
			hagen.log(
				`AUTH`,
				`Email account already exists; signing into it`
			);

			// 	// delete the current anonymous user
			if (firebase.auth().currentUser.isAnonymous) {
				// console.log(`ANON`);
			}
			// 	await firebase.auth().currentUser.delete();

			// log them into the existing email user
			await firebase
				.auth()
				.signInWithEmailLink(email, window.location.href);

			hagen.log(`AUTH`, `Signed in to account`);

			// delete the saved email address from local storage and remove url params
			resetPageState();
		} else {
			hagen.error(
				`AUTH`,
				`Problem linking to anonymous account`
			);
			console.error(error);
		}
	}
};

const resetPageState = () => {
	window.localStorage.removeItem(`emailForSignIn`);
	window.history.replaceState({}, document.title, `/`);
};

export const sendPasswordlessEmail = ({ email }) => {
	firebase
		.auth()
		.sendSignInLinkToEmail(email, {
			url: process.env.SITE_URL,
			handleCodeInApp: true,
		})
		.then(() => {
			hagen.log(`AUTH`, `Sign in email was sent`);
			window.localStorage.setItem(`emailForSignIn`, email);
		})
		.catch((error) => hagen.error(`AUTH`, error));
};

export const signOut = () => {
	hagen.log(`AUTH`, `Signing out`);
	firebase.auth().signOut();
};

export const deleteAccount = async () => {
	const db = firebase.firestore();
	const user = await firebase.auth().currentUser;

	if (!user) return;

	// mark firestore entry as deleted
	await db
		.collection(`users`)
		.doc(user.uid)
		.set({ accountDeleted: true });

	// delete auth entry
	await user.delete();
};

export const createPlayer = async () => {
	const db = firebase.firestore();
	const user = await firebase.auth().currentUser;

	if (!user) return;

	db.collection(`users`).doc(user.uid).set(
		{
			// email: user.email,
			isAnonymous: user.isAnonymous,
		},
		{ merge: true }
	);
};

export const updatePlayerState = async (state) => {
	const db = firebase.firestore();
	const user = await firebase.auth().currentUser;

	if (!user) return;

	db.collection(`users`)
		.doc(user.uid)
		.set(
			{
				...state,
			},
			{ merge: true }
		);
};

export const deletePledge = async () => {
	const db = firebase.firestore();
	const user = await firebase.auth().currentUser;

	if (!user) return;

	db.collection(`users`).doc(user.uid).update({
		pledge: fb.firestore.FieldValue.delete(),
	});
};

export const getPlayerState = async () => {
	const db = firebase.firestore();
	const user = await firebase.auth().currentUser;

	const doc = await db.collection(`users`).doc(user.uid).get();

	return doc.data();
};

export const getPledge = async (uid) => {
	const db = firebase.firestore();
	const doc = await db.collection(`users`).doc(uid).get();
	return doc.data()?.pledge;
};

export const getAllPledges = async () => {
	const NUM_PLEDGES = 200;
	const db = firebase.firestore();
	const snapshot = await db
		.collection(`users`)
		.where(`pledge.pledgeWall`, `==`, true)
		.get();

	const pledges = [];
	snapshot.forEach((doc) => {
		return pledges.push({
			pledge: doc.data()?.pledge,
			uid: doc.id,
		});
	});

	return pledges.filter(
		(pledge) => pledge.pledge?.pledgeWall === true
	);
};

export const saveImage = async ({ image, uid }) => {
	if (!image) return;

	const storage = firebase.storage();
	const signatureRef = storage
		.ref()
		.child(`signatures/${uid}.png`);

	const snapshot = await signatureRef.putString(
		image,
		`data_url`
	);

	const url = await snapshot.ref.getDownloadURL();

	return url;
};

export const logEvent = (event, value) => {
	hagen.log(`GA`, `LOGGED: ${event}: ${JSON.stringify(value)}`);
	firebase.analytics().logEvent(event, value);
};

export const flagSignature = ({ uid, image }) => {
	const db = firebase.firestore();
	db.collection(`reports`).doc(uid).set({
		image,
	});
};

export const setQuestionStats = ({ key, isCorrect }) => {
	const db = firebase.firestore();
	if (isCorrect) {
		db.collection(`questions`)
			.doc(key)
			.set(
				{
					correct: fb.firestore.FieldValue.increment(1),
				},
				{ merge: true }
			);
	} else {
		db.collection(`questions`)
			.doc(key)
			.set(
				{
					incorrect: fb.firestore.FieldValue.increment(1),
				},
				{ merge: true }
			);
	}
};

export const getQuestionStats = async (key) => {
	const db = firebase.firestore();
	const doc = await db.collection(`questions`).doc(key).get();
	const data = doc?.data();

	if (!data) return 0;

	const correct = data?.correct ?? 0;
	const incorrect = data?.incorrect ?? 0;
	const totalRespondants = correct + incorrect;

	const percentage = Math.floor(
		(correct / totalRespondants) * 100
	);
	return Number.isNaN(percentage) ? 0 : percentage;
};
