import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/analytics";

import hagen from "hagen";

let instance;

export default function getFirebase() {
	if (typeof window !== `undefined`) {
		if (instance) return instance;

		instance = firebase.initializeApp(
			JSON.parse(process.env.FIREBASE_CONFIG)
		);

		hagen.log(`GA`, `Start analytics`);
		firebase.analytics();
		instance.analytics();

		return instance;
	}
}
